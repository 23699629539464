.container {
  height: 100%;
}

.cell {
  padding: var(--indent-xs, 8px);
}

.text {
  color: var(--main-dark-gray, #212229);
}
