.container {
  height: 100%;
  background-color: var(--bg-content);
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
}

.text {
  color: var(--color-gray-700);
}
