.container {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.btn {
  max-width: 190px;
  min-width: 190px;
  text-align: center;
}
