.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.description {
  white-space: break-spaces;
  text-align: center;
  color: var(--color-gray-800);
}

.downLoadLink {
  cursor: pointer;
  color: var(--color-blue-500);
}

.fileSection {
  max-height: 180px;
  min-height: 180px;
}
