.wrapper {
  padding: 6px var(--indent-s, 12px);
  white-space: nowrap;
}

.wrapper:not(.noHover):hover {
  background-color: var(--color-gray-200, #f6f7fa);
  border-radius: 4px;
}

.content {
  display: inline-block;
}

.info {
  display: flex;
  align-items: center;
}

.button {
  text-align: end;
}

.noHover {
}

.status {
}

.status .statusIcon {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.statusText {
  padding: 0;
  font-family: Commissioner, sans-serif;
  color: var(--color-gray-800, #5b5f73);
  font-size: var(--main-text-size, 14px);
  font-weight: var(--text-regular, 400);
  line-height: var(--l2-line-height, 17px);
}

.newResponses {
  color: #2ba13e;
}
