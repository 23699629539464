.your-order {
 position: relative;
 cursor: pointer;

 &__title {
   color: #1c50de;
 }

 &__title-total-products {
   color: #1c50de;
 }

 &__title-caret {
   &_rotate {
      transform: rotate(180deg);
   }
 }

 &__list {
    border-radius: 4px;
    box-shadow: 0 6px 10px 0 rgba(118, 123, 146, 0.2);
    border: solid 1px #cccccc;
    background-color: #ffffff;
    width: 248px;
    position: absolute;
    right: 0;
    top: 42px;
    padding: 14px;
 }

 &__item {
   &-title {
    font-size: 12px;
    color: #767b92;
   }

   &-content {
    font-size: 12px;
    color: #333333;
   }
 }

 &__wrapper-btn {
   display: flex;
   justify-content: center;
   padding-top: 14px;
 }

 &__btn {
  font-size: 12px;
  padding: 5px 20px;
 }
}

.order-draft-delimiter {
  margin-bottom: 12px;
}
