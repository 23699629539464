.container {
  height: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--indent-xs, 8px);
  row-gap: var(--indent-xs, 8px);
}

.container:hover {
  background-color: var(--color-gray-200, #f6f7fa);
}

.disableDelete {
}

.container:not(.disableDelete):hover .buttonWrapper {
  visibility: visible;
}

.buttonWrapper {
  visibility: hidden;
}

.buttonWrapper svg path {
  stroke: var(--main-blue, #1c50de);
}

.text {
  display: box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--main-dark-gray, #212229);
  cursor: pointer;
}
