.icon {
  flex-shrink: 0;
  height: 22px;
}

.isRNP > path {
  fill: var(--color-allert);
}

.isRNP > rect {
  stroke: var(--color-allert);
}
