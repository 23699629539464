.container {
  min-height: calc(56px + 2 * var(--indent-m));
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background-color: var(--color-white);
  padding: var(--indent-m);
  flex-shrink: 0;
  position: relative;
}

.titleRow {
  display: flex;
  align-items: center;
  column-gap: var(--indent-xs);
  white-space: nowrap;
  overflow: hidden;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoBlock {
  display: flex;
  column-gap: var(--indent-s);
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--indent-xs);
  overflow: hidden;
}

.between {
  display: flex;
  justify-content: space-between;
  column-gap: var(--indent-s);
}

.fullName {
  color: var(--color-gray-800);
}
