.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cell {
  padding: var(--indent-xs);
  overflow: hidden;
}

.text {
  color: var(--color-gray-700, #7f869e);
}
