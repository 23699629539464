.preview {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 8px;
  width: 210px;
  flex-shrink: 0;
  border-right: 1px solid var(--color-gray-400-hover-element);
}

.requisiteRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 4px;
}

.requisiteRow:hover {
  border-radius: 2px;
  cursor: pointer;
  background: var(--color-gray-400-hover-element);
}

.requisiteRowLabel {
  flex-basis: 38px;
  color: var(--color-gray-700);
  text-align: left;
}

.requisiteRowValue {
  flex-grow: 1;
  color: var(--color-gray-900);
}

.requisites {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}
