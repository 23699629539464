.cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: var(--indent-xs, 8px);
  overflow: hidden;
}

.text {
  color: var(--color-gray-800, #5b5f73);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
