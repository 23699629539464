.item-info {
  display: inline-block
  padding-left: 15px
}

.item-groups {
  display: inline-block
  overflow-y: auto
  overflow-x: hidden
  height: 50px
  column-gap: 1.5em
}

.item-group {
  border-radius: 15px
  padding: 0px 12px 0px 12px
  margin: 2px 3px 3px 3px
  float: left
  background-color: #c6cae3;
  display: inline-block
  float: left
  box-sizing: border-box
}

.item-group-name {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #252b49;
}

.item-name {
  word-break: break-all
  vertical-align: middle;
}

.item-img {
  width: 35px
  height: 35px
  z-index: 1
  padding: 4px
  background-color: #ffffff
  display: inline-block
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px rgba(155, 155, 155, 0.57);
  border-radius: 4px;
}

.panel-selected {
  background-color: #d4ddf0
}
.panel-non-selected {
  background-color: #eee
}

.group-selected {
  color: #1c50de !important;
}

.groups-title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.groups-title {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #252b49;
}

.groups-title-action {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  color: #c6cae3;
}

.groups-title-active {
  color: #252b49;
}

.groups-list {
  font-size: 14px;
  color: #171515;
  padding: 12px 0 6px;
}

.glypths {
  display: inline-block;
  float: right;
}

.glypth-groups {
  cursor: pointer;
  display: inline-block;
  padding-left: 10px;
  color: #767b92;
}

.svg-icon-edit {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("/img/edit-group.svg");
}

.svg-icon-edit:hover {
  width: 20px;
  height: 20px;
  opacity: 0.9;
  background-repeat: no-repeat;
  background-image: url("/img/edit-group.svg");
}

.svg-icon-delete {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("/img/delete-group.svg");
}

.svg-icon-delete:hover {
  width: 20px;
  height: 20px;
  opacity: 0.9;
  background-repeat: no-repeat;
  background-image: url("/img/delete-group.svg");
}

.line-separator {
  border-top: 1px solid #ccc;
  margin: 15px 0;
}

.btn-new-group {
  width: 256px;
  height: 42px;
  font-size: 16px;
}

.groups-panel {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.groups-panel__list {
  overflow-y: auto;
  height: 100%;
}
