.stepWidthBig {
  max-width: 420px !important;
}

.wrapper {
  max-width: 310px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--padding-m);
}

.fieldset {
  position: relative;
  padding: 0;
  width: 100%;
}

.fieldsetBorder {
  border: 2px dashed var(--color-gray-600);
  border-radius: 8px;
  max-width: 420px;
  min-width: 420px;
}

.stepCardText {
  position: absolute;
  top: -20px;
  right: 2px;
  color: var(--color-gray-800);
}

.text {
  margin: 0 0 0 70%;
  padding: 0 10px;
  width: auto;
  font-family: Commissioner;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: var(--color-gray-800);
}

.step {
  position: relative;
  margin: 50px 0;

  /* padding: 50px 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.noParallelStep {
  margin: 0;
}

.stepButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-s);
}

.stepButtons button {
  max-width: 90%;
  width: 100%;
}

.agreementCards {
  display: flex;
  flex-direction: column;
  gap: 37px;
  position: relative;
}

.acceptDiscardIcons {
  margin: auto;
  position: absolute;
  top: 76px;
  right: -82px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.deleteIconWrapper {
  position: absolute;
  top: -12px;
  right: -36px;
  background: var(--color-white);
  max-width: 30px;
  max-height: 30px;
  padding: var(--padding-xxs);
}
