.item {
  display: flex;
  flex-direction: row;
  gap: var(--padding-xs);
  align-items: center;
  padding: var(--padding-xs);
  background: var(--color-white);
  transition: background linear 200ms;
}

.item:hover {
  background: var(--color-blue-50);
  cursor: pointer;
}
