.panel-top_theme_default,
.panel-top_theme_messenger {
  height: 100%
  background-color: #fff
  border-radius: 0
  padding-right: 25px
  display: flex
  align-items: center
  justify-content: space-between
  position: relative
  z-index: 10
}

.panel-top_theme_chat {
  height: 100%
  background-color: inherit
  border-radius: 0
  padding-right: 25px
  display: flex
  align-items: center
  justify-content: space-between
  position: relative
  z-index: 10
}

.panel-top_theme_gray {
  height: 100%
  background-color: inherit
  border-radius: 0
  padding-right: 25px
  display: flex
  align-items: center
  justify-content: space-between
  position: relative
  z-index: 10
}
