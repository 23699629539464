.layout {
}

.header {
  height: 56px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f2f4f8;
  padding: 0 20px;
  position: relative;
  z-index: 11;
}

.headerLogo {
  width: 210px;
  background: #fff;
  height: 100%;
  margin-left: -20px;
  display: flex;
  padding-left: 20px;
  align-items: center;
}

.headerColumnLeft {
  height: 100%;
}

.headerColumnItem {
  margin-left: 40px;
}

.headerColumnRight {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.headerSeparator {
  width: 5px;
  height: 100%;
}

.userName {
  font-size: 14px;
  font-weight: 500;
  color: #767b92;
  padding: 0 5px;
  user-select: none;
  display: flex;
}

.content {
  display: flex;
}

.navigation {
  background: #fff;
  box-shadow: 4px 0 16px rgb(36 78 171 / 5%);
  height: calc(100vh - 56px);
  position: relative;
  z-index: 2;
}

.mainContent {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;
  z-index: 1;
  position: relative;
}

.panel {
  height: 56px;
  min-height: 56px;
}

.panelChat {
  display: none;
}

.panelGray {
  height: 74px;
  color: #252b49;
}

.sectionContent {
  display: grid;
  grid-template-columns: 1fr max-content;
  height: calc(100vh - 56px);
  width: 100%;
  overflow-x: auto;
}

.innerContent {
  width: 100%;
  padding: 34px;
  overflow-x: auto;
}

.contentMessenger {
  width: 100%;
  padding: 16px 34px 50px 42px;
  overflow-x: auto;
}

.contentChat {
  width: 100%;
  padding: 16px 34px;
  overflow-x: auto;
}

.contentGray {
  padding: 8px 34px 34px;
}

.sidebar {
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
}

.navigation-simple {
  padding: 10px 16px 10px 26px;
}

.navigation-simple__section-name {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #252b49;
}

.navigation-simple__section-list {
  padding-top: 9px;
}

.navigation-simple__link-item {
  display: flex;
  color: #767b92;
  font-size: 14px;
  padding-top: 3px;
  align-items: center;
}

.navigation-simple__link-item_active,
.navigation-simple__link-item_active:hover {
  color: #1c50de;
}

.navigation-simple__link-item:not(.navigation-simple__link-item_active):hover {
  color: #163881;
}

.navigation-simple__badge {
  background: #1c50de;
  border-radius: 45px;
  font-size: 10px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  margin-left: auto;
  height: 13px;
  width: 22px;
}

.navigation-simple__divider {
  margin-top: 8px;
  margin-bottom: 5px;
  border-color: #767b92;
}
