.badge {
  max-width: 110px;
  width: 100%;
  text-align: center;
  border-radius: 2px 2px 0 0;
  color: var(--color-white);
  padding: 4px 0;
}

.begin {
  background: var(--color-gray-700);
}

.agreement {
  background: var(--color-blue-500);
}

.introduction {
  background: var(--color-additionaly-orange);
}

.publication {
  background: var(--color-additionaly-grass);
}
