.wrapper {
  width: 100%;
}

.droppedBtns {
  position: absolute;
  right: 0;
  bottom: -35px;
  display: flex;
  flex-direction: row;
  gap: var(--padding-xs);
  align-items: center;
  padding: 4px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-300);
  box-shadow: 0 0 16px rgb(66 61 99 / 13%);
  border-radius: 2px;
}

.editableWrapper {
  position: relative;
  width: 100%;
  background: var(--color-white);
  border: 1px solid var(--color-gray-800);
  border-radius: 2px;
}

.editableInput {
  width: 100%;
  padding: 7px 8px;
  border: none;
}

.title {
  color: var(--color-gray-800);
}

.acceptButton svg path {
  fill: var(--color-additionaly-grass);
}

.discardButton svg path {
  fill: var(--color-gray-900);
}

.staticContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  flex-wrap: wrap;
}

.hoveredContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background: var(--color-blue-50);
  padding: 8px;
}

.hoveredText {
  color: var(--color-gray-900);
}
