.extraOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px;
}

.extraOptions.marginOff {
  margin-left: 0;
  margin-right: 0;
}

.mainTextColor {
  color: var(--color-gray-700);
}

.extraOptionsBlock {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 20px;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: var(--color-gray-900);
  padding-bottom: 16px;
  width: calc(100% - 48px);
}

.titleText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extraOption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 33%;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-400);
  border-radius: 4px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-400);
  margin-bottom: 12px;
}

.ETA {
  width: 180px;
}

.costOfDelivery {
  width: 100px;
}

.prepayment {
  width: 120px;
}

.optionExtension {
  display: flex;
  gap: 12px;
  align-items: center;
}

.extraOptionViewMode {
  display: flex;
  justify-content: flex-start;
  gap: 64px;
}

.viewModeOption {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.viewModeTitle {
  color: var(--color-gray-900);
  margin-left: 4px;
}

.viewModeTitle::after {
  content: ':';
}

.viewModeValue {
  color: var(--color-gray-700);
}
