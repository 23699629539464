.set-category {
  position: relative;
  z-index: 5;

  &__header {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid #b8bcd3;
    padding: 10px 10px 10px 7px;
  }

  &__input {
    background-color: #fff !important;
  }

  &__toggle-panel {
    position: absolute;
    background-color: #fff;
    width: 208px;
    border-radius: 4px;
    box-shadow: 0 6px 10px 0 rgba(118, 123, 146, 0.2);
    margin-top: 3px;
    display: none;
    right: 0;

    &_show {
      display: block;
    }
  }

  &__list {
    padding: 10px 7px 7px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 28px;
      position: absolute;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
      pointer-events: none;
    }
  }

  &__list-scroll {
    height: 190px;
    overflow-x: hidden;
    width: 120%;
    padding-bottom: 14px;
  }

  &__item {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    margin-top: 8px;
    width: 194px;

    &:first-child {
      margin-top: 0px;
    }
  }

  &__radio-btn {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #c6cae3;
    cursor: pointer;
    margin-right: 6px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: '';
      position absolute;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
    }

    &_set {
      border: 1px solid #bbb;

      &:after {
        background-color: #000;
      }
    }
  }

  &__to-child {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: auto;
    cursor: pointer;
  }

  &__title {
    font-size: 12px;
    color: #000;
    width: 100%;
    display: inline-flex;
    align-items: center;

    &_pointer {
      cursor: pointer;
    }
  }

  &__control-panel {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #b8bcd3;
    height: 30px;
  }

  &__control-item {
    cursor: pointer;
    display: inline-flex;

    &_disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
