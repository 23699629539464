.list-of-categories {
  width: 100%;

  &__panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 39px;
    margin-bottom: 4px;
    background-color: #f7f7f7;
    font-weight: 500;
    border-bottom: 1px solid #dad3d3;
  }

  &__name {
    margin-left: 14px;
  }

  &__products {
    margin-right: 450px;
  }

  &__children-panel {
    display: flex;
    height: 39px;
    width: 100%;
    justify-content: '';
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #dad3d3;
  }

  &__checkbox {
    margin: 0;
  }

  &__tree-view {
    display: flex;
    margin-bottom: 2px;

    &_label {
      display: flex;
      width: 100%;
      justify-content: '';
      align-items: center;
    }

    &_children {
      display: flex;
      height: 39px;
      width: 100%;
      justify-content: '';
      align-items: center;
      margin-left: 4px;
      padding-left: 19px;
      border-bottom: 1px solid #dad3d3;
    }
  }
}

.list-of-category {
  .panel-group {
    .panel-title.panel-header {
      font-size: 16px
      padding: 10px 10px 10px 20px
      line-height: 1.1
      cursor: pointer
    }
    .panel-title.panel-header:hover {
      text-decoration: underline;
    }
  }
}

.tree-view_item {
  display: flex
  border-bottom: 1px solid #dad3d3
  height: 39px
}

.tree-view {
  overflow-y: hidden;
  width: 100%
}

.tree-view_arrow {
  font-size: 20px
  margin-left: 3px
}

.tree-view_arrow:after {
  content: url('/img/open.svg') !important;
  width: 11px
}
