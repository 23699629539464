.text {
  display: flex;
  align-items: center;
  max-width: max-content;
  word-break: break-word;
}

.title {
  color: #767b92;
}

.icon {
  margin-left: 4px;
}

.iconActive path {
  stroke: var(--color-blue-500);
}

.canUpdate:hover {
  cursor: pointer;
  padding: 4px;
  margin: -4px;
  background: var(--color-gray-300);
  border-radius: 5px;
}
