.icon {
  display: flex;
  width: 18px;
  height: 18px;
}

.pending svg path {
  stroke: var(--color-gray-800);
}

.resolve svg path {
  stroke: var(--color-second-green);
}

.reject svg path {
  stroke: var(--color-red-error);
}
