.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  background-color: #fff;
  overflow: hidden;
}

.container.noHover:hover {
  filter: none;
}
