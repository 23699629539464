.forms_theme_default {
  .forms__row {
    &_inline {
      display: flex
      padding-bottom: 15px
    }

    .form-group {
      margin-bottom: 0
    }
  }

  .forms__label {
    padding-top: 6px
    width: 200px
    flex-shrink: 0
    color: #8e8d8d
    font-size: 14px
    font-weight: normal
  }

  .forms__input-control {
    width: 100%
    max-width: 400px
    position: relative

    &_half-width {
      width: 50%
      max-width: 200px
      margin-top: 6px
    }

    .help-block {
      font-size: 12px
      position: absolute
      left: 0
      margin-top: 2px
    }
  }

  .forms__label-count {
    font-size: 10px
    color: #adabad
    text-align: right

    &_absolute {
      position: absolute
      width: 100%
    }
  }
}

.usrle-select-popup {
  width: 307px;
  max-height: 300px;
  overflow: auto;
}

.usrle-select-option {
  padding: 5px 8px;
  cursor: pointer;
  color: #767b92;
  white-space: break-spaces;
}

.usrle-select-option:hover {
  color: #252b49;
  background: rgba(242,244,248,.5);
}