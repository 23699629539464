.breadcrumbs_theme_default {
  padding-left: 40px;
  background-color: transparent
  margin-bottom: 0
  display: flex;

  .breadcrumbs {
    &__item {
      font-size: 12px;

      a {
        color: #767b92;

        &:hover {
          color: #1c50de;
        }
      }

      span {
        color: #252b49;
        cursor: default;
      }
    }

    &__item + .breadcrumbs__item:before {
      content: "/";
      color: #767b92;
      padding: 0 3px;
    }
  }
}

.breadcrumbs_theme_messenger {
  padding-left: 40px;
  background-color: transparent
  margin-bottom: 0
  font-size: 18px;

  .breadcrumbs {
    &__item {
      font-size: 18px;

      a {
        color: #767b92;

        &:hover {
          color: #1c50de;
        }
      }

      span {
        color: #252b49;
        cursor: default;
      }
    }

    &__item + .breadcrumbs__item:before {
      content: "/";
      color: #767b92;
      padding: 0 3px;
    }
  }
}

.breadcrumbs_theme_gray {
  padding: 26px 16px 26px 34px;
  background-color: transparent
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
  display: flex;

  .breadcrumbs {
    &__item {
      font-size: 18px;

      a {
        color: #767b92;

        &:hover {
          color: #1c50de;
        }
      }

      span {
        color: #252b49;
        cursor: default;
      }
    }

    &__item + .breadcrumbs__item:before {
      content: "/";
      color: #767b92;
      padding: 0 3px;
    }
  }
}
  
.breadcrumbs_theme_chat {
  display: none;
}

.breadcrumb > li {
  display: flex;
}
