.wrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 11px 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
  background: var(--color-white);
  z-index: 999;
}

.rightButtons {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.exitBtn {
  border: 0;
  color: var(--color-gray-700);
  background-color: var(--color-white);
}

.snackbarIcon svg path {
  stroke: var(--color-gray-500);
}
