.container {
  display: flex;
  column-gap: var(--indent-m);
  align-items: center;
  padding-bottom: var(--indent-s);
}

.backButton {
  height: 34px;
}

.backButton circle {
  fill: var(--color-white);
  stroke: var(--divider-color);
}

.backButton path {
  fill: var(--color-gray-800);
}

.title {
  color: var(--main-dark-gray);
}
