.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
}

.readyBtn {
  border-radius: 22px !important;
  color: var(--color-white) !important;
  outline: none !important;
  padding: 9px 16px !important;
  background-color: var(--color-blue-500) !important;
  border-color: transparent !important;
}

.readyBtn:disabled {
  background: var(--color-gray-600) !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.inputBlock {
  background: var(--color-white);
}
