.title {
  color: var(--main-dark-gray);
}

.column.withHelper {
  display: flex;
  column-gap: var(--indent-xxs);
}

.columnTitle {
  color: var(--color-gray-700);
  height: 18px;
}

.rowTitle {
  color: var(--main-dark-gray);
}
