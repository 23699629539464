.generalContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 28px;
  background-color: var(--color-white);
  width: 100%;
  color: var(--color-gray-900);
}

.block {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #000000d9;
}

.blockTitle {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 4px;
}

.dash {
  align-self: center;
  color: #767b92;
}

.fullSize {
  display: flex;
  flex: 0 0 100%;
}

.quarterSize {
  width: 270px;
}

.etaSize {
  width: 182px;
}

.generalInput {
  background-color: var(--color-white);
  padding: 8px 16px;
  margin-right: 8px;
  border: 1px solid #ebeef4;
  border-radius: 4px;
  color: var(--color-gray-900);
}

.generalInput::placeholder {
  color: #c6cae3;
}

.select:active {
  border: none;
}

.selectDisabled div {
  background-color: #eee;
}

.readOnly {
  background-color: #eee;
  color: #555;
  pointer-events: none;
}

.dataBlock {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.calendar:active {
  border: none;
}

.horizontalRule {
  border: none;
  margin: 0;
  border-top: 1px solid var(--color-gray-300);
  width: 100%;
}

.required {
  position: relative;
  left: -2px;
  top: -2px;
  color: #f00;
  content: '*';
}

.infoBlock {
  width: auto;
}

.counter {
  margin-left: auto;
}

.error {
  color: #fd4f53;
}
