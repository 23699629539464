.head {
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  margin-bottom: 8px;
}

.headText a {
  color: var(--color-gray-900);
}

.headText a:hover {
  color: var(--main-blue);
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.contactRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.contactRowText {
  color: var(--color-gray-800);
}

.content {
  width: 100%;
}

.mainInfo {
  padding: 0 0 12px 8px;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  row-gap: var(--indent-xs);
}

.custom {
  padding-left: 8px;
}
