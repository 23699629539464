.breadcrumbs-buttons {
  margin: 0;
  padding: 0;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__common {
    display: inline-block;
    padding: 18px 10px;
    font-weight: 500;

    &_active {
      cursor: pointer;
      color: #767b92;
      text-decoration: none;
    }

    &_disabled {
      color: #c6cae3;
    }
  }

  &__register {
    display: inline-block;
    padding: 18px 10px;
    font-weight: 500;

    &_active {
      cursor: pointer;
      color: #1c50de;
      text-decoration: none;
    }

    &_active:hover {
      color: #767b92;
    }

    &_active:active {
      color: #252b49;
    }
  }

  &__item {
    display: inline-block;
    font-weight: 500;
    color: #767b92;

    & > * {
      display: inline-block;
      padding: 18px 10px;
    }

    &_active,
    &_active a {
      cursor: pointer;
      color: #767b92;
      text-decoration: none;
    }

    &_disabled {
      color: #c6cae3;
    }
  }

  &__item_active:hover {
    color: #1c50de;

    a {
      color: #1c50de;
    }
  }

  &__item_active:active {
    color: #252b49;

    a {
      color: #252b49;
    }
  }
}

.breadcrumbs-buttons-dropdown {
  border: 0 !important;
  font-weight: 500;
  color: #767b92;
  background-color: transparent !important;
  box-shadow: none !important;


}

.no-caret .caret {
  display: none;
}

.breadcrumbs-buttons-dropdown:hover {
  color: #1c50de !important;
}

.dropdown.open .breadcrumbs-buttons-dropdown .caret {
  transform: rotate(180deg);
}

.dropdown_menu_item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  font-family: 'Roboto', 'sans-serif' !important;
}

.dropdown_menu_item:hover,
.dropdown_menu_item:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

 .breadcrumbs-buttons-dropdown .caret {
   border: none;
   width: 13px;
   height: 7px;
   background-image: url("/img/arrow_dropdown_button.svg");
   background-repeat: no-repeat;
 }

.breadcrumbs-buttons-dropdown:hover,
.breadcrumbs-buttons-dropdown:active {
  background-color: transparent !important;
  box-shadow: none !important;
}

.control-buttons-dropdown {
  border: 0 !important;
  font-weight: 500;
  color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.control-buttons-dropdown:hover {
  color: #252b49 !important;
}

.control-buttons-dropdown:hover,
.control-buttons-dropdown:active {
  background-color: transparent !important;
  box-shadow: none !important;
}

.breadcrumbs-buttons_theme_gray {
  border: none;
}

.breadcrumbs-buttons_theme_gray, .breadcrumbs-buttons__item > * {
  padding: 8px 16px;
  display: flex;
}

.breadcrumbs-buttons__icon {
  margin: -2px 12px -2px 0;
}
