.categories {
  display: flex;
  flex-direction: row;
  row-gap: 4px;
  column-gap: 4px;
  flex-wrap: wrap;
  align-items: center;
}

.category {
  padding: 4px 8px;
  background: var(--color-gray-300);
  border-radius: 18px;
}

.categoryText {
  color: var(--color-gray-900);
}

.emptyCategories {
  color: var(--color-gray-700);
}
