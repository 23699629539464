.unsubscribe {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-blue-50);
  border-radius: 6px;
}

.container {
  width: 890px;
  height: 500px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0 0 19px rgba(61 75 119 / 14%);
  border-radius: 6px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.image {
  width: 208px;
  height: 190px;
}

.mainText {
  max-width: 344px;
  color: var(--color-gray-900);
  margin: 0 28px;
  padding: 0 36px;
  white-space: pre-wrap;
}

.mainTextSuccess {
  padding: 12px 36px;
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.textBlock {
  color: var(--color-gray-900);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email {
  color: var(--color-gray-900);
  max-width: 890px;
  padding: 0 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
