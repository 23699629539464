.container {
  display: flex;
  gap: 4px;
}

.list {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.emptyGroups {
  align-items: center;
}

.emptyGroupsText {
  color: var(--color-gray-600);
}

.title {
  color: var(--color-gray-700);
}

.group {
  color: var(--main-dark-gray);
}

.groups {
  display: flex;
  align-items: center;
  gap: 4px;
}
