.container {
  padding: var(--indent-l);
  border: 1px solid var(--divider-color);
  background-color: var(--color-white);
}

.divider {
  border-color: var(--color-gray-500);
  margin: 20px 0;
}
