.about {
  width: 100%;
  display: flex;
  gap: 28px;
  padding: 16px 16px 24px;
  background-color: var(--color-white);
}

.oneColumnLayout {
  display: flex;
  flex-direction: column;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  gap: 10px;
}

.section {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-gray-700);
  text-transform: uppercase;
  height: 25px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  width: 100%;
}

.attributesBlock {
  margin-top: 4px;
}

.attributeTitle {
  display: flex;
  justify-content: flex-start;
  color: var(--color-gray-700);
}

.attributeValue {
  display: flex;
  justify-content: flex-end;
  text-align: end;
  flex-wrap: wrap;
  color: var(--color-gray-900);
}

.copyAttributes {
  display: flex;
  flex-direction: row;
  gap: 6px;
  cursor: pointer;
  color: var(--color-gray-800);
}

.delimiter {
  height: 8px;
  width: 100%;
  border-top: 1px solid var(--color-gray-300);
}

.contactsBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
}

.dialBlock {
  display: flex;
  flex-direction: column;
  color: var(--color-gray-900);
  gap: 6px;
}

.string {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.site {
  color: var(--color-blue-500);
}

.site:hover {
  color: var(--color-blue-700);
}

.site:visited {
  color: var(--color-blue-300);
}

.dialRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dialType::after {
  content: '.:';
}

.noOverview {
  color: var(--color-gray-600);
}

.overview {
  color: var(--color-gray-900);
  display: block;
}

.button > span > span:last-child {
  padding-right: 0 !important;
}
