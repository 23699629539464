.snackbar {
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, -50%);
  padding: 18px;
  display: flex;
  gap: 12px;
  align-items: center;
  text-align: center;
  color: var(--color-white);
  background: var(--color-gray-800);
  box-shadow: 0 1px 4px rgb(118 123 146 / 50%);
  border-radius: 4px;
  max-width: 700px;
  width: 100%;
}

.animation {
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

@keyframes fadeIn {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.closeIcon {
  cursor: pointer;
  margin-left: auto;
}

.closeIcon svg path {
  fill: var(--color-white);
}

.text {
  text-align: left;
  color: var(--color-white);
}
