.emojiSection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.emojiSelectButton {
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border: none;

  /* height: var(--cw-header-height); */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.emojiSelectButton:focus {
  outline: 0;

  /* reset for :focus */
}

.emojiBtnImgHover {
  display: none;
}

.emojiSelectButton:hover .emojiBtnImgHover {
  display: block;
}

.emojiSelectButton:hover .emojiBtnImg {
  display: none;
}

.emojiSelectButtonActive .emojiBtnImgHover {
  display: block;
}

.emojiSelectButtonActive .emojiBtnImg {
  display: none;
}

.emojiPicker {
  visibility: hidden;
  position: absolute;
  bottom: 48px;
  right: 0;
  z-index: 1051;
}

.emojiPickerShow {
  visibility: visible;
}
