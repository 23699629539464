.content {
  margin: 0 auto;
  max-width: 489px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.text {
  color: var(--color-gray-800);
}

.constructorList {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.snackbarIcon svg path {
  fill: var(--color-white);
}
