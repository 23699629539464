.btn {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  /* отображаем курсор в виде руки при наведении; некоторые
  считают, что необходимо оставлять стрелочный вид для кнопок */
  cursor: pointer;
  padding: 4px;
  max-width: 22px;
  max-height: 22px;
}
