.workspace-header{
  margin-bottom: 16px;
}

.nav-tabs > li.active {
  border-bottom: 2px solid #252b49 !important;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500 !important;
  color: #767b92 !important;
  border: 0 !important;
}

.nav-tabs > li > a:hover {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #1c50de !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #252b49 !important;
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
