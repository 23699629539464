.sidebar_theme_default {
  width: 294px
  height: 100%
  background-color: #fff;
  padding: 38px 18px 80px
  overflow-x: auto
  display: none
  border-left: 1px solid #f2f4f8
}

.sidebar_theme_wide {
  width: 429px
  height: 100%
  background-color: #fff;
  padding: 38px 18px 80px
  overflow-x: auto
  display: none
  border-left: 1px solid #f2f4f8
}

.sidebar {
  &_show {
    display: block;
  }

  a.panel-btn-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panel-btn-bottom {
    width: 256px
    position: fixed
    bottom: 20px
    font-size: 16px
    height: 42px
  }
}

.button_open_arrow_sidebar {
  background: url('/img/sidebar_arrow.svg') no-repeat center center;
  width: 14px
  margin-left: 5px
  margin-right: 5px
  cursor: pointer
}

.button_close_arrow_sidebar {
  background: url('/img/sidebar_arrow.svg') no-repeat center center;
  transform: rotate(180deg)
  width: 14px
  margin-left: 5px
  margin-right: 5px
  cursor: pointer
}
