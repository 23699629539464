.wrapper {
  background: var(--color-white);
  border: 1px solid var(--color-gray-300);
  width: 100%;
  border-radius: 2px;
  padding: var(--padding-xs);
  cursor: pointer;
}

.readonly {
  cursor: default;
}

.container {
  display: flex;
  flex-direction: row;
  gap: var(--padding-xs);
  align-items: center;
}

.avatar {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: rgb(18 18 18 / 3%);
  border-radius: 50%;
}

.avatar img {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--padding-xxs);
}

.header {
  display: flex;
  flex-direction: row;
  gap: var(--padding-xxs);
  align-items: center;
}

.title {
  color: var(--color-gray-900);
}

.department {
  white-space: nowrap;
  overflow: hidden;
  max-width: 175px;
  text-overflow: ellipsis;
  color: var(--color-gray-700);
}

.dropdownWrapper {
  margin-left: auto;
  position: relative;
}

.menu {
  position: absolute;
}

.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-xxs);
}

.defaultText {
  color: var(--color-gray-900);
}

.errorText {
  color: var(--color-red-error);
}

.redTrash svg path {
  fill: var(--color-red-error);
}

.redTrash {
  display: flex;
  cursor: pointer;
}

.line {
  margin: 3px 0;
  width: 100%;
  height: 1px;
  background: var(--color-gray-300);
}
