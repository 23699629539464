.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  /* fix filter width */
  max-width: 262px;
  width: 100%;
  justify-content: flex-end;
}
