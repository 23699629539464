.demo-logout-btn {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-weight: 500;
  color: #767b92;

  &_disabled {
    color: #c6cae3;
  }

  &_active,
  &_active a {
    cursor: pointer;
    color: #767b92;
    text-decoration: none;
  }

  &_active:hover {
    color: #1c50de;

    a {
      color: #1c50de;
    }
  }

  &_active:active {
    color: #252b49;

    a {
      color: #252b49;
    }
  }
}
