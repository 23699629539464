.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoBlock {
  background: var(--color-blue-50);
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
}

.headerTitle {
  margin: 8px 0;
}

.icon path {
  stroke: var(--color-blue-300);
}

.infoText {
  color: var(--color-gray-800);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.inputs {
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.isHidden {
  overflow: hidden;
}

.emailRequestInput {
  width: 100%;
  position: relative;
}

.error {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: var(--color-red-error);
}
