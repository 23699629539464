.main {
  background: var(--color-gray-300);
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.container h1 {
  white-space: nowrap;
}

.content {
  margin: 156px 5% 100px 15%;
  width: 100%;
  display: flex;
  gap: 100px;
  align-items: center;
  position: relative;
}

.content > div:last-child {
  padding-right: 60px;
}

.snackbarIcon svg path {
  fill: var(--color-white);
}
