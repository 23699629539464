.btn {
  padding: var(--padding-xs);
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg > path {
  fill: var(--color-gray-700);
}

.btn:hover,
.btn:active {
  transition: background 300ms linear;
  background: var(--color-gray-300);
  border-radius: 4px;
}

.btn:hover svg > path {
  fill: var(--color-gray-900);
}

.btn:active svg > path {
  fill: var(--color-blue-500);
}

.active svg > path {
  fill: var(--color-blue-500);
}
