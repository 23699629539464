.header-disclaimer {
    font-size: 14px;
    line-height: 16px;
    color: #252B49;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.header-disclaimer__register {
    margin: 0;
    display: inline-block;

    &_demo {
      padding: 0;
      cursor: pointer;
      color: #1c50de;
      text-decoration: none;
    }

    &_demo:hover {
      color: #1c50de;
    }

    &_demo:active {
      color: #252b49;
    }

    a {
        color: #252b49;
    }
}
