.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}

.controlPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.searchInput {
  width: 383px;
}

.actions {
  display: flex;
  gap: 20px;
}

.list {
  flex-grow: 1;
}
