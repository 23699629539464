.collapsibleContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  color: #252b49;
  margin: 12px 0;
}

.generalInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
}

.generalInfoTitle {
  color: var(--color-gray-700);
  font-weight: var(--text-regular);
}

.generalInfoTitle::after {
  content: ' - ';
}

.generalInfoContent {
  color: var(--color-gray-900);
  font-weight: var(--text-semibold);
}

.horizontalRule {
  color: var(--color-gray-300);
  width: 100%;
}

.logContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.logListContent {
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  gap: 8px;
}

.logListContent:hover {
  background-color: var(--color-gray-200);
  border-radius: 4px;
}

.logListContent:hover .iconShow {
  visibility: visible;
}

.logListTitle {
  color: var(--color-gray-600);
  font-weight: var(--text-bold);
  padding: 4px 0;
}

.logList {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.personName,
.personTitle {
  white-space: nowrap;
  font-weight: var(--text-regular);
  padding-right: 20px;
}

.personName {
  color: var(--color-gray-800);
}

.personTitle {
  color: var(--color-gray-700);
  overflow: hidden;
  text-overflow: ellipsis;
}

.personTitle::before {
  content: '(';
}

.personTitle::after {
  content: ')';
}

.disclaimer {
  margin-top: 12px;
  padding: 4px 8px;
  background-color: var(--color-gray-200);
  border-radius: 2px;
  border-left: 2px solid var(--color-blue-500);
}

.iconShow {
  visibility: hidden;
}
