.table {
  padding: var(--indent-xs, 8px);
}

.table tr {
  height: 100%;
}

.table tr > td {
  height: 0;
}

@-moz-document url-prefix("") {
  .table tr > td {
    height: 100%;
  }
}

.table tr > td:hover > div {
  filter: drop-shadow(0 0 16px rgb(66 61 99 / 13%));
}

.table thead tr > th {
  background-color: #fff;
  text-align: left;
}

.table tr > th::before {
  display: none;
}

.table tr > td,
.table tr > th {
  padding: 6px 1px;
  border: none;
}

.row:nth-child(2) td {
  padding-top: 0;
}

.row:last-child td {
  padding-bottom: 0;
}

::-webkit-scrollbar-track {
  background-color: var(--color-gray-200, #f6f7fa);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(12 35 98 / 20%);
  border-radius: 13px;
}

/* TODO проверить без important */
.table thead tr {
  position: relative !important;
  z-index: 6 !important;
}
