.btn {
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
  border: 1px dashed var(--color-blue-500);
  width: 100%;
  border-radius: 4px;
}

.text {
  color: var(--color-blue-500);
}

.icon {
  max-width: 16px;
  max-height: 16px;
}

.icon svg path {
  fill: var(--color-blue-500);
}

.isError {
  border: 1px dashed var(--color-red-error);
}

.iconError svg path {
  fill: var(--color-red-error);
}

.textError {
  color: var(--color-red-error);
}

.big {
  padding: 20px 12px;
}

.disabled {
  opacity: 0.5;
}

/* full */

/* .iconError svg path { */

/*  fill: var(--color-red-error); */

/* } */

.textError {
  color: var(--color-red-error);
}

/* text */

/* .textError{ */

/*  color: var(--color-red-error); */

/* } */

/* !*icon*! */

/* .iconError svg path { */

/*  fill: var(--color-red-error); */

/* } */
