.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: contain;
}
