.cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: var(--indent-xs, 8px);
  background-color: var(--color-gray-200, #f6f7fa);
}

.text {
  color: var(--color-gray-700, #7f869e);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
