.wrapper {
  position: relative;
  max-width: 310px;
  min-width: 310px;
  width: 100%;
}

.small {
  max-width: 186px !important;
  min-width: 186px !important;
}

.card {
  display: flex;
  flex-direction: column;
}

.badge {
  position: absolute;
  top: -21px;
  left: 0;
}

.content {
  position: relative;
  padding: 16px;
  background: var(--color-white);
  box-shadow: 0 1px 4px rgb(118 123 146 / 30%);
  border-radius: 5px;
}

.textStart {
  color: var(--color-black);
}

.acceptText {
  color: var(--color-additionaly-grass);
}

.discardText {
  color: var(--color-red-error);
}

.publishIcon {
  display: flex;
  border-radius: 50%;
  z-index: 10;
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  background: var(--color-additionaly-grass);
  padding: 4px;
  margin: auto;
  position: absolute;
  top: 50%;
  right: -23px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.deleteIconWrapper {
  position: absolute;
  top: 0;
  right: -36px;
  background: var(--color-white);
  max-width: 30px;
  max-height: 30px;
  padding: var(--padding-xxs);
}

.error {
  position: absolute;
  bottom: -16px;
  left: 0;
  color: var(--color-red-error);
}
