.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.rightSideContent {
  display: flex;
  align-items: center;
}

.allPurchaseRequestsLink {
  color: var(--color-gray-800);
}

.allPurchaseRequestsLink:hover {
  color: var(--color-gray-800);
}

.currentPurchaseRequest {
  color: var(--color-gray-900);
  display: flex;
  gap: var(--padding-xs);
  align-items: center;
}

.arrowDown svg {
  transform: rotate(180deg);
}

.btn {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
}

.btnText {
  color: var(--color-gray-800);
}
