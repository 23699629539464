@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');

body {
  min-width: 1200px;
  font-family: 'Roboto', 'sans-serif' !important;
}

.tooltip {
  font-family: 'Roboto', 'sans-serif' !important;
}

b, strong {
  font-weight: 500;
}

.note-editor b, .note-editor strong {
  font-weight: bold;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.header .contact-sup {
  margin-top: 8px;
}

.application .note-editor.note-frame {
  border: 1px solid #efefef;
}

.header .navbar-default .navbar-nav > li > a {
  color: #808080;
  font-size: 14px;
}

.header .navbar {
  background-color: #f7f7f7;
  border-bottom: 1px solid #cccccc;
}

.link {
  display: inline
  background: transparent
  border: none
  cursor: pointer
  padding: 0
  outline: none
  color: #428bca;

  &_disabled,
  &_disabled[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: .65;
  }

  &:hover,
  &:focus {
    text-decoration: underline
  }
}

.link-alert {
  font-weight: 500;
  color: #fff !important;
}

.navigation .nav > li > a {
  padding: 10px 10px;
  color: #428bca;
}

.navigation .nav li.active a .badge {
  color: #ffffff;
}

.navigation .nav li.active a,
.navigation .nav li.active a:hover,
.navigation .nav li.active a:focus,
.navigation .nav li.active a:active {
  background-color: #428bca;
}

.navigation .nav a {
  border-radius: 0;
}

/*.workspace {
  padding-top: 8em;
}*/
/*@media (max-width: 768px){
  body {
    padding-top: 195px;
  }
}*/

.sqwear180 {
  position: relative;
  height: 180px;
  width: 180px;
}

.sqwear100 {
  position: relative;
  height: 100px;
  width: 100px;
}

.pageHeader h2 {
  margin: 0 !important;
  padding: 15px 0 !important;
}

.pageHeader .breadcrumb {
  margin: 0 !important;
  padding: 22px 0 22px;
  background-color: transparent;
}

.btn-img-choose {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.btn-img-add {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%
  transform: translate(-50%, -50%);
  background: url('/img/plus_image.svg') no-repeat center;
  width: 100%;
  height: 100%;

  &:hover {
    background-image: url('/img/plus_image_hover.svg');
  }

  &:active {
    background-image: url('/img/plus_image_active.svg');
  }
}

.btn-img-remove {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%
  transform: translate(-50%, -50%);
  background: url('/img/delete_image.svg') no-repeat center;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:hover {
    background-image: url('/img/delete_image_hover.svg');
    opacity: 1;
  }

  &:active {
    background-image: url('/img/delete_image_active.svg');
  }
}

.pdf-cell {
  background: url('/img/pdf_small_icon.png') left center no-repeat;
  background-size: 40px;
  height: 38px;
  padding-left: 50px;
}

.product-pictures {
  padding-right: 15px;
}

.sidebar-error {
  background-image: url('/img/download_error.png');
  background-repeat: no-repeat;
  background-position: 0 11px;
  padding-left: 50px;
  padding-top: 12px;
  line-height: 30px;

  padding-bottom: 5px;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  color: #808080;
}

.product-picture {
  width: 180px;
  height: 180px;
  display: block;
  float: left;
  margin-top: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.catalog-sidebar-pending {
  color: #999;
  position: absolute;
  top: 51px;
}

.catalog-sidebar-pending-title {
  font-size: 28px;
  padding: 15px 51px;
  line-height: 30px;
  background-image: url("/img/arrows_circle.png");
  background-size: 36px;
  background-position: left center;
  background-repeat: no-repeat;
}

.catalog-sidebar-pending-body {
  font-size: 14px;
}

.sidebar button {
  display: block;
}

.sidebar .sidebar-group {
  margin-bottom: 30px;
}

.sidebar-group-item a {
  color: #333333;
}

.sidebar .sidebar-group-item + .sidebar-group-item {
  margin-top: 5px;
  display: block;
}

.suggestion-btn-wrap {
  color: #ffffff;
  font-size: 16px;
  display: block;
  width: 100%
  border-radius: 0;
  height: 42px;
  background-color: #767b92;
  border: none;
  padding: 0 0 0 16px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

/* Исправление постоянного отображения скроллбара в ant таблице */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #c6cae3;
}

::-webkit-scrollbar-thumb {
  background-color: #767b92;
}

.workspace-header a,
table a {
  color: #428bca;

  &:hover {
    text-decoration: none
  }
}

a {
  cursor: pointer
}

.workspace-header .nav-tabs > li.active > a {
  color: #7e7e7e;
}

.template-btn-wrap .span {
  display: inline-block;
}

.template-btn-wrap .template-btn-text {
  line-height: 21px;
}

.template-btn-wrap .glyphicon {
  vertical-align: top;
  font-size: 18px;
  color: #a7a7a7;
}

.template-btn-wrap a {
  font-weight: 500;
}

.import-catalog__container-select .form-group .form-control {
  font-size: 14px;
}

.import-catalog__container-button-download .btn {
  font-size: 14px;
}
.note-editor.panel {
  margin-bottom: 0
}

[role="menu"][class="dropdown-menu"][aria-labelledby="selectPriceCategory"] {
  max-height: 300px;
  overflow-x: auto;
}

.text-description {
  font-size: 14px;
  white-space: pre-wrap;
}

.glypth-save-pright {
  cursor: pointer;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.glypth-remove-pleft {
  display: inline-block;
}

.glypth-pleft {
  cursor: pointer;
  display: inline-block;
  padding-left: 10px;
  color: #747175;
}

.glypth-contacts {
  cursor: pointer;
  display: inline-block;
  color: #747175;
  vertical-align: middle;
  font-size: 16px;
  padding-left: 5px;
}

.glypth-conditions {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.glypth-conditions-remove {
  cursor: pointer;
  display: inline-block;
  color: #747175;
}

.glypth-cond-remove {
  top: 2px !important;
  padding-left: 5px;
}

.conditions-header {
  margin-top: 15px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.condition-option {
  padding-bottom: 5px;
}

.option-name {
  vertical-align: bottom;
  color: #000000;
  min-height: 18px;
  width: 100%;
  display: inline-block;
  position: relative;
  top: 1px;
}

.add-option-text {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #767b92;
}

.add-option-glypth {
  top: 0 !important;
  padding-right: 5px;
}

.option-input {
  display: inline-block;
  height: 24px;
  width: 98%;
  margin-bottom: -2px;
  padding-left: 8px;
  padding-top: 0;
  padding-bottom: 0;
  color: #000;
}

.simple-checkbox {
  height: 18px;
  width: 18px;
  vertical-align: top;
  margin-right: 5px !important;
}

.price-checkbox {
  vertical-align: top;
  margin-right: 5px !important;
}

.pointer-cursor {
  cursor: pointer;
}

.white-space-paragraph {
  white-space: pre-line;
}

.text-center {
  font-size: 17px;
}

.text-header {
  font-size: 24px;
}

.profile-img {
  width: 180px
  height: 180px
  z-index: 1
  background-color: #ffffff
  display: inline-block
  border: solid 1px rgba(155, 155, 155, 0.57);
}

.contacts-img {
  width: 214px
  height: 214px
  z-index: 1
  background-color: #ffffff
  display: inline-block
  border: solid 1px rgba(155, 155, 155, 0.57);
}

.request-img {
  width: 110px
  height: 110px
  z-index: 1
  background-color: #ffffff
  display: inline-block
  border: solid 1px rgba(155, 155, 155, 0.57);
  box-sizing: border-box;
}

.form-group-label-col {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  text-align: left !important;
  width: 230px;
  padding-top: 5px !important;
}

.form-group-input-col {
  width: 400px;
}

.input-col-length {
  font-family: inherit;
  font-weight: 500;
  font-weight: normal;
  line-height: 1;
  color: #777;
  font-size: 11px;
}

.form-modal-label-col {
  font-weight: normal;
  font-size: 14px;
  text-align: left !important;
  padding-top: 5px !important;
}

.profile-option-label {
  font-size: 14px;
  display: inline-block;
  padding-right: 5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #767b92;
}

.profile-option-content {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #252b49;
}

.profile-option {
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  justify-content: space-between;
}

.company-profile-btn {
  height: 42px;
  margin-top: -8px;
  padding: 6px 24px !important;
}

.col-sm-profile {
  position: relative;
  min-height: 1px;
  float: left;
}

.col-sm-profile2 {
  width: 18.666667% !important;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
}

.price-price::-webkit-inner-spin-button {
  display: none;
}

.additions-container {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: -13px;
}

.product-image {
  width: 38px;
  height: 38px;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
  object-fit: contain;
}

.product-image.no-border {
  border: none;
}

th, label {
  font-weight: 500;
  margin-bottom: 4px;
}

.text-content-center {
  display: flex
  justify-content: center
}

.text-content-left {
  display: flex
  justify-content: left
}

.form-content-center {
  display: flex
  justify-content: center
}

.form-content-center .Select-placeholder {
  color: #C6CAE3;
}

.form-button-center {
  display: flex
  justify-content: center
}


.btn-default:disabled {
  border-color: #c6cae3;
  color: #c6cae3;
}

.btn-cancel,
.btn {
  border-radius: 22px;
  font-weight: 500;
  outline: none !important;
  padding: 9px 16px;
  line-height: 1;
}

.btn-cancel {
    background: transparent!important;
    border: none!important;
}

.btn-cancel:hover {
    background: transparent!important;
    color: inherit!important;
}

.btn-primary {
  background-color: #1c50de;
  border-color: transparent;
}

.btn-primary:hover {
  background-color: #0638c0;
  box-shadow: 0 6px 14px 0 rgba(6, 56, 192, 0.3);
}

.btn-primary:active {
  background-color: #163881;
  box-shadow: 0 6px 14px 0 rgba(6, 56, 192, 0.3);
}

.text-btn-primary {
  color: #1c50de;
  border-color: transparent;
  background-color: transparent;
  font-size: 14px;
  outline: none;
}

.text-btn-primary:hover {
  color: #0638c0;
}

.text-btn-primary:active {
  color: #163881;
}

.btn-primary:disabled {
  background-color: #c6cae3;
  border: inherit;
  box-shadow: none;
}

.btn-default {
  background-color: transparent;
  border: solid 1px #767b92;
  color: #767b92;
}

.btn-default-disabled {
  background-color: #fff;
  border: solid 1px #767b92;
  color: #767b92;
}

.btn-default:hover {
  background-color: #767b92;
  border-color: transparent;
  color: #fff;
}

.btn-default:hover path {
  fill: #fff;
}

.btn-default:active {
  background-color: #6a6f83;
  border-color: transparent;
  color: #fff;
}

.btn-default[disabled]:hover {
  color: #c6cae3;
}

.btn-primary[disabled]:hover {
  background-color: #c6cae3;
}

.btn-default[disabled] path,
.btn-default[disabled]:hover path {
  fill: rgb(118, 123, 146);
  opacity: 0.65;
}

.msg-icon {
  float: left;
  padding-right: 10px;
}

.alert {
  padding: 10px 0 0px 40px !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.alert-success {
  color: #ffffff !important;
  background-color: #767B92;
  border-color:  #767B92;
}

.alert-warning {
  color: #ffffff !important;
  background-color: #00cee0 !important;
  border-color: #00cee0 !important;
}

.alert-danger {
  color: #ffffff !important;
  background-color: #fd4f53 !important;
  border-color: #fd4f53 !important;
}

.alert-info {
  color: #ffffff !important;
  background-color: #6a6f83 !important;
  border-color: #6a6f83 !important;
}

.close {
  color: #ffffff !important;
  right: 0 !important;
  top: 0 !important;
  padding-right: 25px !important;
  font-size: 32px !important;
  opacity: 1 !important;
  font-weight: 100 !important;
  margin-top: -6px;
}

.sr-only {
  color: #ffffff !important;
  width: inherit !important;
  height: inherit !important;
  overflow: visible !important;
  display: inherit !important;
}

.Select-arrow-zone {
  border-left: 1px solid #EBEEF4;
  width: 36px !important;
}

.Select-arrow {
  background: url(/img/select-arrow-down.svg) 5px 2px no-repeat;
  width: 24px !important;
  height: 18px !important;
  border: none !important;
}

.Select-arrow-rotated {
  transform: rotate(180deg);
}

.prfs .Select.is-open {
  margin-bottom: 20px;
}

.Select.is-open > .Select-control .Select-arrow {
  transform: rotate(180deg);
  background-position: 1px -3px;
}

.modal-title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-content > .modal-header {
  border-bottom: none;
}

.modal-body > div > .form-group > .control-label {
  font-size: 12px !important;
}

.help-block {
  font-size: 9px;
  color: #fd4f53 !important;
  white-space: break-spaces;
  margin-bottom: 0px;
}

.modal-footer > .btn.btn-default {
  border: 0;
  color: #767b92;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.modal-footer > div > .btn.btn-default {
  border: 0;
  color: #767b92;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.modal-footer > .btn.btn-default:hover {
  border: 0;
  color: #767b92;
  background-color: #fff;
}

.modal-footer > div > .btn.btn-default:hover {
  border: 0;
  color: #767b92;
  background-color: #fff;
}

.modal-footer > .btn.btn-primary {
  border: 0;
  color: #1c50de;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.modal-footer > div > .btn.btn-primary {
  border: 0;
  color: #1c50de;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}
.modal-footer > div > .btn.btn-primary:disabled, .modal-footer > .btn.btn-primary:disabled {
  color: #c6cae3;
}

.modal-footer > div > .btn.btn-primary:hover {
  border: 0;
  color: #1c50de;
  background-color: #fff;
  box-shadow: none;
}

.modal-footer > .btn.btn-primary:hover {
  border: 0;
  color: #1c50de;
  background-color: #fff;
  box-shadow: none;
}

.modal-header {
  border-radius: 4px;
  border: none;
  padding-top: 25px;
  padding-bottom: 0;
}

.modal-header-cross {
  background: url('/img/cross.svg') no-repeat center;
  width: 14px;
  height: 14px;
  margin-top: 4px
  cursor: pointer
}

.modal-header-cross-top {
  background: url('/img/cross.svg') no-repeat center;
  cursor: pointer
  position: absolute;
  right: 0;
  top: 0;
  background-color: #F2F4F8;
  width: 34px;
  height: 34px;
  border-radius: 0 8px 0 0;
}

.modal-body, .modal-header, .modal-footer {
  padding-left: 42px;
  padding-right: 42px;
  border-top: none;
  clear: both;
}

.status-wrap {
  display: inline-flex;
  position: relative;
}

.request-card-wrapper {
    .status-icon {
      width: 10px;
      height: 10px;
      margin-top: 7px;
  }

  .status-text {
    font-size: 14px;
    margin-left: 18px;
    color: #767B92;
  }
}

.status-icon {
  width: 11px;
  height: 11px;
  margin-top: 4px;
  border-radius: 50%;
  position: absolute;
}

.color-icon {
  width: 11px;
  height: 11px;
  margin-right: 8px;
  border-radius: 50%;
}

.status-text {
  color: #252b49;
  font-size: 12px;
  padding-top: 2px;
  margin-left: 15px;
}

.help-ul {
  -webkit-padding-start: 0px !important;
  margin-left: 15px;
}

.help-ul li a {
  color: #1c50de;
}

.help-ul li a:active,
.help-ul li a:hover {
  color: #252b49;
}

.help p {
  padding: 10px 0;
  color: #252b49;
}

.help p a,
.help .link {
  color: #1c50de;
}

.demo-link {
  text-align: center;
  margin-top: 40px;
}

.btn-demo {
  width: 155px;
  height: 42px;
  border-radius: 22px;
  background-color: #1c50de;
  box-shadow: 0 6px 14px 0 rgba(6, 56, 192, 0.3);
  padding-top: 10px;
}

#companyModalWindow>.modal-dialog {
  width: 750px;
}

.badge {
  width: 22px;
  height: 13px;
  display: inline-flex;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 45px;
  background-color: #fd4f53;
  margin-left: auto;
  align-items: center;
}

#availableBadge {
  margin-top: 4px;
}

.table-control-wrap {
  display: flex;
  float: right;
}

.grid-wrap-order {
  display: grid;
  grid-row-gap: 25px;
  grid-template-columns: repeat(2, 270px);
}

.grid-wrap-order-three {
  display: grid;
  grid-row-gap: 25px;
  grid-template-columns: repeat(3, 270px);
}

.grid-col-order {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.grid-col-wide {
  grid-column-start: 1;
  grid-column-end: 3;
}

.grid-row-order {
  color: #252b49;
  padding-top: 15px
}

.grid-row-label {
  font-size: 12px;
  color: #767b92;
}

.control-label-row {
  font-size: 12px;
  color: #767b92;
  font-weight: normal;
}

.control-label-row-request {
  display:flex;
  flex-direction row;
  align-items:center;
  margin-bottom:18px;
  font-weight: 500;
  font-size: 14px;
  color: #252B49;
}

.form-row-margin {
  margin-bottom: 15px;
}

.tooltip-arrow {
  display: none;
}

.tooltip-inner {
  font-size: 11px
  color: #252b49
  border-radius: 2px
  background-color: #f2f4f8
  padding: 2px 6px
  user-select: none
  pointer-events: none
  text-align: left
  max-width: 300px
}

.tooltip.bottom {
  margin-top: 0
}

.dropdown-menu > li > a {
  padding: 5px 0px 0px 10px !important;
  outline: none !important;
}

.set-category__input-wrapper>.input-group-btn>.btn {
  border-bottom-right-radius: 4px
  border-top-right-radius: 4px
  border: 1px solid #ccc
  background-color: #fff
}

.set-category__input-wrapper>.input-group-btn>.btn>.caret {
  content: url('/img/sidebar_arrow.svg')
  width: 14px
  transform: rotate(90deg)
  border: 0
  width: 14px
  height: 14px
}

.set-category__input-wrapper>.input-group-btn>.btn:hover {
  background-color: #fff
  border: 1px solid #ccc
}

.note-btn {
  border-radius: 4px
}

.profile-image-wrap {
  position: absolute;
  right: 0;
}

.label-profile {
  z-index: 1;
  top: 3px;
}

.img-profile {
  top: 28px;
  width: 281px;
  height: 281px;
  z-index: 1;
}

.profile-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
}

.profile-category-sign {
  margin-left: 15px;
  margin-top: -25px;
  cursor: pointer;
}

.price-stocks-sign {
  margin-left: 10px;
  margin-top: -25px;
  cursor: pointer;
}

.price-stock-select {
  width: 96%;
  display: inline-block;
}

.price-stock-select-modal {
  width: 89%;
  display: inline-block;
}

.contacts-sign {
  margin-left: 8px;
  margin-top: -6px;
  cursor: pointer;
}

.modal-label {
  color: #252b49;
  text-align: left !important;
  padding-top: 5px !important;
}

.col-modal {
  position: relative;
  min-height: 1px;
  float: left;
}

.col-modal.xs-1 {
  width: 8.33333333%;
}

.col-modal.xs-2 {
  width: 16.66666667%;
}

.col-modal.xs-3 {
  width: 25%;
}

.col-modal.xs-4 {
  width: 33.33333333%;
}

.pd-t-10 {
  padding-top: 10px;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.dp-inline {
  display: inline-block;
}

hr {
  border-color: #c6cae3
  margin-top: 17px
  margin-bottom: 17px
}

.header-with-line {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  margin-top: 17px
  margin-bottom: 17px

  &__text {
    background-color: #fafbfc;
    position: relative;
    z-index: 1;
    padding-right: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    background-color: #c6cae3;
  }
}

.import-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.product-description {
  font-family: 'Roboto', 'sans-serif' !important
}

.product-description>p>span {
  font-family: 'Roboto', 'sans-serif' !important
  font-size: 14px !important
}

.red-dot-table {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #fd4f53;
}

.table-dot-wrapper {
  display: flex;
  align-items: center;
  padding: 7px 4px;
  border: 0.5px solid #EBEEF4;
  border-radius: 4px;
  justify-content: space-between;
  line-height: 1;
}

.table-dot-wrapper span {
  margin-bottom: 3px;
  margin-left: 0px;
}

.red-dot {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  background-color: #fd4f53;
  border-radius: 50%;
  position: absolute;
  opacity: 1;
  z-index: 1;
  margin-top: 5px
  margin-left: -25px
}
.blue-dot {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  background-color: #1c50de;
  border-radius: 50%;
  position: absolute;
  opacity: 1;
  z-index: 1;
  margin-top: 5px
  margin-left: -25px
}

.form-control {
  font-family: Roboto;
  border: 1px solid #EBEEF4;
  box-shadow: none;
}

.form-control::placeholder {
  color: #C6CAE3;
}

.input-group .form-control {
  z-index: 0 !important;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.modal-checkbox-label {
  vertical-align: middle;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #252b49;
}

.btn-lg {
  font-size: 16px;
  padding: 10px 38px;
}

.Select {
  font-size: 14px;
}

.Select-input,
.Select .Select-control {
  height: 32px;
}

.Select-control:hover {
  box-shadow: none;
}

.Select-menu-outer {
  border-color: #c6cae3 !important;
  z-index: 100 !important;
}

#employee-select-billing .Select-menu-outer .Select-menu {
  height: 115px !important;
}

.Select-arrow-zone {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.input-group-addon {
  background-color: transparent;
  padding: 0 10px;
}

.product-picture {
  position: relative;

  &:after {
    content: attr(data-text);
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 11px;
    color: #767b92;
  }
}

.menuitem-style {
  cursor: default;
  padding: 5px 0px 0px 10px !important;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.order-col-property {
  height: 74px
}

.forms_type_order {
  padding-top: 25px
  width: 50%
  border-right: 1px solid #ddd
}

.comment-own-comment {
  background-color: #ffffff
  width: 75%
  display: block
  margin: 5px
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 5px
  float: left
  border-radius: 4px
}

.comment-partner-comment {
  background-color: #f0f1f7
  width: 75%
  display: block
  margin: 5px
  float: right
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 5px
  border-radius: 4px
}

.Select-value-label > .status-wrap > .status-icon {
  margin-top: 11px
}

.Select--single .Select-value-label {
  display: block;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: calc(100% - 30px) !important;
}

.Select--multi .Select-value {
  display: inline-flex;
}


.Select--single .Select-value-label .status-wrap {
  display: flex;
  justify-content: space-between;
  padding-right: 6px;
}


.Select--multi .Select-value .Select-value-icon {
  display: inline-flex;
  align-items: center;
}

.Select--multi .Select-multi-value-wrapper {
  padding-bottom: 5px;
  padding-right: 5px;
}

.Select--multi .Select-input {
  display: none !important;
}

.Select-option .status-wrap {
  display: flex;
  justify-content: space-between;
  padding-right: 36px;
}

.order-comment {
  overflow-wrap: break-word
  word-break: break-word;
  white-space: pre-wrap;
}

.order-comment-date {
  padding-left: 5px
  color: #767b92
  font-size: 12px
}

.order-comment-company-name {
  font-weight: 500
  font-size: 12px
}

#order-comments-container {
  height: 345px
  overflow: auto
}

.order-top-info {
  display: inline-flex
  width: 100%
  flex-wrap: wrap
  border-bottom: 1px solid #ddd
}

.order-sidebar-title {
  font-size: 12px
  color: #767b92
}

.order-sidebar-value {
  font-size: 14px
  color: #252b49
  margin-top: 16px
}

.order-sidebar-property {
  margin-top: 20px
}

.order-sidebar-send-button {
  position: absolute
  bottom: 20px
  width: 220px
  height: 42px
  text-align: center
  font-size: 16px
  box-shadow: 0 6px 14px 0 rgba(6, 56, 192, 0.3)
  margin-left: 20px
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: default;
}

.Select-value {
  z-index: 10
}

.help-message {
  font-size: 11px;
  padding-left: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #eb5757;
}

.modal_header_text {
  padding-left: 37px;
  padding-right: 37px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(119, 119, 119);
  background-color: #fafbfc;
}

.has-error .col-modal .input-number {
  border: 1px solid red;
}

.has-error .col-modal div {
  color: red;
}

.img-thumbnail {
  border-color: #c6cae3;
}

.return-link {
  display: inline-block;
  font-size: 14px;
  color: #252b49;
  font-weight: 500;
}

.catalog-product-physical-param {
  display: block;
  width: 100% !important;
  height: 34px !important;
}

.form-horizontal .form-group {
  margin-left: -10px;
  margin-right: -10px;
}

.two-columns {
  column-count: 2;
}

#invalidFieldMessage {
  color: #FD4F53 !important;
}

.has-error>input.form-control,
.has-error>.set-category>.set-category__input-wrapper>.form-control,
.has-error>div>.form-control,
.has-error>div>.input-group>.form-control,
.has-error>div>.input-group>.input-group-addon {
  border-color: #FD4F53 !important;
}

.has-error>label.control-label {
  color: #FD4F53;
}

.has-error>.input-group>.input-group-addon {
  color: #FD4F53;
  border-color: #FD4F53 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.image-control {
  width: 281px;
  height: 281px;
  position: relative;
  z-index: 1;

  &__container {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.img-loading-error {
  display: flex;
  textAlign: center;
  alignItems: center;
  justifyContent: center;
  whiteSpace: normal;
  height: 100%;
  color: #4a4a4a;
  fontSize: 12px;
}

.textarea-description {
  padding: 7px;

  & * {
    font-family: inherit !important;
    font-size: inherit !important;
  }
}

.stock-title {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding-bottom: 10px;
}

.select-text-left {
  color: #252b49;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  padding: 5px 0 0 0;
}

.Select-placeholder {
  color: #C6CAE3 !important;
}

.modal-dialog-wrap .modal-dialog {
  width: 650px !important;
}

.menuitem-a-option {
  cursor: pointer;
  textDecoration: none;
  fontWeight: 500;
  fontSize: 14px;
  color: #252b49;
}


.order-filter-tag {
  display: flex;
  background-color: #c6cae3;
  border-radius: 4px;
  padding: 0px 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
}

.order-filter-tag-cross {
  margin-left: 5px;
  width: 10px;
}

input::-ms-clear {
  display: none;
}

.orders-stock-col1 {
  padding-left: 0 !important;
  color: #767b92;
  font-size: 12px;
  width: 11.5% !important;
  padding-top: 3px;
}

.orders-stock-col2 {
  padding-left: 0 !important;
  color: #252b49;
}

.wide-modal .modal-dialog {
  width: 800px !important;
  margin: 30px auto;
}

.autosize-modal .modal-dialog {
  width: auto !important;
  min-width: 600px;
  max-width: 830px;
  margin: 30px auto;
}

.order-part-title {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.order-table-show-more {
  cursor: pointer;
  color: #1d4fde;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.t-a-r {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tariff-title-font {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #767b92;
}

.tariff-block {
  width: 300px;
  height: 400px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(118, 123, 146, 0.5);
  padding: 20px;
  letter-spacing: 1.2px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.exp {
  width: 375px;
}

.block-content-center {
  text-align: center;
}

.tariff-subtitle {
  font-size: 12px;
  letter-spacing: 1.2px;
}

.tariff-block-description {
  line-height: 1.64;
  letter-spacing: normal;
}

.tariff-flag {
  background-color: #1c50de;
  width: 20px;
  height: 26px;
  top: 0;
  right: 20px;
  position: absolute;
}
.tariff-flag::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid white;
}

.billing-info-wrap {
  width: 50%;
  padding: 18px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 1px rgba(118, 123, 146, 0.5);
  background-color: #ffffff;
}

.billing-info-row {
  margin-top: 8px;
  margin-bottom: 6px;
}

.transparent-header {
  border-color: transparent;

  & > .panel-heading {
    background-color: transparent;
    border-color: transparent;
  }
}

.billing_info_button {
  border-left: 1px solid #f2f4f8;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  font-size: 16px;
  justify-content: space-between;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  color: #1c50de;
  text-decoration: none;
}

.billing_info_button:hover {
  color: #767b92;
}

.switch-btn {
  display: inline-block;
  width: 50px; /* ширина */
  height: 25px; /* высота */
  border-radius: 25px; /* радиус скругления */
  background: #bfbfbf; /* цвет фона */
  z-index: 0;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
  transition-duration: 300ms; /* анимация */
}
.switch-btn::after {
  content: "";
  height: 23px; /* высота кнопки */
  width: 23px; /* ширина кнопки */
  border-radius: 17px;
  background: #fff; /* цвет кнопки */
  top: 1px; /* положение кнопки по вертикали относительно основы */
  left: 1px; /* положение кнопки по горизонтали относительно основы */
  transition-duration: 300ms; /* анимация */
  position: absolute;
  z-index: 1;
}
.switch-on {
  background: #1c50de;
}
.switch-on::after {
  left: 26px;
}

.switch-btn-ver2 {
  display: inline-block;
  width: 36px; /* ширина */
  height: 16px; /* высота */
  border-radius: 25px; /* радиус скругления */
  background: #bfbfbf; /* цвет фона */
  z-index: 0;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
  transition-duration: 300ms; /* анимация */
}
.switch-btn-ver2::after {
  content: "";
  height: 14px; /* высота кнопки */
  width: 14px; /* ширина кнопки */
  border-radius: 17px;
  background: #fff; /* цвет кнопки */
  top: 1px; /* положение кнопки по вертикали относительно основы */
  left: 1px; /* положение кнопки по горизонтали относительно основы */
  transition-duration: 300ms; /* анимация */
  position: absolute;
  z-index: 1;
}
.switch-on-ver2 {
  background: #1c50de;
}
.switch-on-ver2::after {
  left: 21px;
}

.pale-placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dadde8;
  opacity: 1; /* Firefox */
}

.pale-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #dadde8;
}

.pale-placeholder::-ms-input-placeholder { /* Microsoft Edge */
  color: #dadde8;
}

.text-normal {
  color: #252b49;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  padding: 5px 0 0 0;
}

.button-report {
  display: block
  width: 187px
  height: 42px
  margin-left: auto
  margin-right: auto
}

.row-delimiter {
  border-top: 1px solid #C6CAE3;
  margin-top: 15px;
  margin-bottom: 15px;
}

.request-img-row {
  width: 110px
  height: 110px
  z-index: 1
  background-color: #ffffff
  display: inline-block
  box-sizing: border-box;
}

.request-row-content {
  margin-left: 5px;
  overflow-wrap: break-word;
}

.endhour-select .Select-menu-outer {
  width: 95px !important;
}

.sort-select .Select-menu-outer {
  top: auto !important;
}

.nav-badge {
  width: 22px;
  height: 13px;
  display: inline-flex;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 45px;
  background-color: #fd4f53;
  opacity: 1;
}

.add-partner-copy {
  height: 34px;
  width: 40px;
  outline: none;
  border: 0.5px solid #C6CAE3;
  border-radius: 0 4px 4px 0;
  background: #FFFFFF;
  padding-bottom: 2px;
}

.alert-text {
  display: inline;
}

#chat-notifications-tooltip {
  max-width: 300px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.note-editor .note-editing-area .note-editable[contenteditable="false"] * {
  opacity: 0.6;
}

.files-dropdown {
  padding-left: 0;
  padding-right: 0;
  color: #1C50DE !important;
  font-weight: normal;
  font-size: 14px;
}

.breadcrumbs-buttons-dropdown.files-dropdown .caret {
  background: url('/img/icon_sort_blue.svg') no-repeat center;
}

.textlimit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popover-text-wrap .popover-content {
  white-space: break-spaces;
}

.btn-add-by-link {
  padding: 10px 25px;
  border-color: #1C50DE;
  color: #1C50DE;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    background-color: #1C50DE;
    color: white;
  }

  &:focus,
  &:active,
  &:active:focus,
  &:hover:active {
    background-color: #1C50DE;
    color: white;
    border-color: #1C50DE;
    box-shadow: inset 0 0 3px #000;
  }
}

.note-btn-group h1, .note-btn-group .h1, .note-editing-area h1 {
    font-size: 36px !important;
}

.btn-go-pr-subscribe {
  place-self: center;
}
