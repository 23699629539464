.employees {
  box-sizing: border-box;
  height: 100%;
  overflow-y: clip;
}

.row {
  display: flex;
  flex-grow: 1;
  padding: 12px 28px;
  gap: 28px;
  border-bottom: 1px solid var(--color-gray-300);
  background-color: var(--color-white);
}

.employees:last-child {
  border-bottom: none;
}

.header {
  padding: 8px 28px;
  background-color: var(--color-blue-50);
  color: var(--color-gray-900);
}

.headerRow {
  display: flex;
  align-items: center;
  flex-basis: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  color: var(--color-gray-800);
}

.name {
  color: var(--color-gray-900);
}

.tel {
  display: flex;
  flex-grow: 1;
  gap: 24px;
  color: #000;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.contactAdded {
  box-sizing: border-box;
  stroke: var(--color-second-green);
  margin: 3px;
}

.contactAdded > path {
  stroke: var(--color-second-green);
}

.disabled {
  stroke: var(--color-gray-600);
}

.disabled > path {
  stroke: var(--color-gray-600);
}

.delimiter {
  height: 1px;
  width: 100%;
  border-top: 1px solid var(--color-gray-300);
}

.contactsAreHidden {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: var(--color-gray-800);
}
