.modal-container_type_add-products-to-stock {
  .modal-container {

    &__row {
      display: flex
      align-items: center

      &.modal-container__row_with-input {
        &:not(:first-child) {
          margin-top: 7px
        }
      }

      &:not(:first-child) {
        margin-top: 15px
      }
    }

    &__title {
      font-size: 14px
      color: #9b9b9b
      width: 145px
      flex-shrink: 0
    }

    &__value {
      width: 100%
    }

    &__input {
      &_count {
        width: 75px
      }
    }

  }
}