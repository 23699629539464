.wrapper {
  background: var(--color-white);
  border-bottom: 1px solid var(--color-gray-300);
  box-shadow: 0 6px 10px rgb(118 123 146 / 20%);
  border-radius: 1px;
  width: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  max-width: 80%;
  width: 100%;
}

.title {
  color: var(--color-blue-500);
  margin-bottom: 5px;
}

.description {
  color: var(--color-gray-800);
}
